@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./node_modules/flag-icons/css/flag-icons.min.css";

@layer utilities {
    /* remove blue tap box on mobile that is set by default on webkit, looks strange on some buttons and provides no additional utility */
    .no-highlight {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

}
